<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>产品码管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                v-model="queryForm.season"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="解锁产品" prop="productDetailId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.productDetailId"
                                clearable
                            >
                                <el-option
                                    v-for="item in codeTypeList"
                                    :key="item.productDetailId"
                                    :label="item.productName"
                                    :value="item.productDetailId"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否使用" prop="isUsed">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.isUsed"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_isUsed"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否分配" prop="allocation">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.allocation"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_allocation"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否禁用" prop="state">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.state"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_isForbidden"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="创建人" prop="creator">
                            <el-input
                                v-model="queryForm.creator"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="描述信息" prop="codeDescription">
                            <el-input
                                v-model="queryForm.codeDescription"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getDataList('search')"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 学校列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="9">
                    <span class="title_class">兑换码信息</span>
                </el-col>
                <el-col :span="15">
                    <div class="div_algin_right">
                        <el-button
                            type="primary"
                            @click="editInfoDialogVisible = true"
                            >新增兑换码+</el-button
                        >
                        <el-button
                            type="primary"
                            plain
                            @click="downloadCodeData"
                            icon="el-icon-download"
                            >批量导出</el-button
                        >
                        <el-button type="primary" @click="importCodeClicked"
                            >批量导入</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    label="产品名称"
                    prop="productFlag"
                    :formatter="codeTypeFormatter"
                ></el-table-column>
                <el-table-column label="赛季" prop="season"></el-table-column>
                <el-table-column label="兑换码" prop="code"></el-table-column>
                <el-table-column label="有效天数" prop="days">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.days">-</span>
                        <span v-else>{{ scope.row.days }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否分配" prop="allocation">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.allocation === true"
                            size="mini"
                            >已分配</el-tag
                        >
                        <el-tag type="warning" v-else size="mini"
                            >未分配</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="是否使用" prop="isUsed">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.isUsed === true"
                            size="mini"
                            >已使用</el-tag
                        >
                        <el-tag type="warning" v-else size="mini"
                            >未使用</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="是否禁用" prop="state">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.state === true"
                            size="mini"
                            >未禁用</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">已禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="使用人"
                    prop="userName"
                ></el-table-column>
                <el-table-column
                    label="使用时间"
                    prop="useTime"
                ></el-table-column>
                <el-table-column
                    label="创建人"
                    prop="creator"
                ></el-table-column>
                <el-table-column
                    label="描述信息"
                    prop="codeDescription"
                ></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="showChangePopViiew(scope.row)"
                            >分配修改</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 编辑信息的dialog -->
        <el-dialog
            title="添加兑换码信息"
            :visible.sync="editInfoDialogVisible"
            width="40%"
            @close="editInfoDialogClose"
        >
            <el-form
                :model="editInfoForm"
                label-width="140px"
                ref="editInfoFormRef"
                :rules="editInfoRules"
                style="width: 100%"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="解锁产品类型" prop="productDetailId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="editInfoForm.productDetailId"
                                value-key="productDetailId"
                                @change="codeTypeChange($event)"
                            >
                                <el-option
                                    v-for="item in codeTypeList"
                                    :key="item.productDetailId"
                                    :label="item.productName"
                                    :value="item.productDetailId"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="生成数量" prop="num">
                            <el-input
                                v-model="editInfoForm.num"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="天数" prop="days">
                            <el-input
                                v-model="editInfoForm.days"
                                placeholder="请输入"
                                type="number"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="描述信息" prop="codeDescription">
                            <el-input
                                maxlength="100"
                                type="textarea"
                                v-model="editInfoForm.codeDescription"
                                placeholder="请输入描述信息(不超过100字)"
                                clearable
                            ></el-input> </el-form-item
                    ></el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editInfoDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editInfo">保 存</el-button>
            </span>
        </el-dialog>
        <!-- 修改是否分配状态 -->
        <el-dialog
            title="分配修改"
            :visible.sync="distributionStateDialogVisible"
            width="40%"
        >
            <el-form
                :model="changeQueryForm"
                label-width="140px"
                ref="changeQueryFormRef"
                :rules="changeQueryFormRules"
                style="width: 90%"
            >
                <el-form-item label="是否分配" prop="allocation">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="changeQueryForm.allocation"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_allocation"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="描述信息" prop="codeDescription">
                    <el-input
                        maxlength="100"
                        type="textarea"
                        v-model="changeQueryForm.codeDescription"
                        placeholder="请输入描述信息(不超过100字)"
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="changeState()" type="primary"
                    >确 定</el-button
                >
                <el-button @click="distributionStateDialogVisible = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>
        <!-- 批量导入解锁码 -->
        <el-dialog
            title="批量导入兑换码"
            :visible.sync="importCodeDialogVisible"
            @close="importScoreDialogClose"
            width="30%"
        >
            <span style="color: red">注*仅可修改匹配状态和禁用状态</span>
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :action="scoreImportInfo.uploadUrl"
                    ref="scoreImportInfoRef"
                    :data="{}"
                    :headers="scoreImportInfo.headerObj"
                    :on-progress="scoreImportInfoProgress"
                    :on-success="scoreImportInfoSuccess"
                    :on-error="scoreImportInfoError"
                    :on-exceed="scoreImportInfoExceed"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="onChangeLoad"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="beginUpLoadData"
                        >确认上传</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { queryConversionCodeList, addConversionCode, updateAllocation, getDetailFlagList } from '@/http/api'
export default {
  data () {
    return {
      scoreImportInfo: {
        uploadUrl: this.$env.baseIP + 'code/importConversionCodeList',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      importCodeDialogVisible: false,
      changeQueryForm: {
        codeDescription: ''
      },
      distributionStateDialogVisible: false,
      provincesSchoolList: [],
      editInfoDialogVisible: false,
      showEditInfoForm: false,
      dataList: [],
      total: 0,
      userInfoData: {},
      userInfoForm: {
        phone: ''
      },
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        productDetailId: null,
        pageNum: 1,
        pageSize: 10,
        state: null,
        creator: null,
        codeDescription: ''
      },
      editInfoForm: {
        productDetailId: null,
        num: null,
        days: null,
        codeDescription: ''
      },
      changeQueryFormRules: {
        allocation: { required: true, message: '请选择', trigger: 'change' }
      },
      userInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_season: [],
      editInfoRules: {
        codeDescription: [{ required: true, message: '请输入', trigger: 'blur' }],
        productDetailId: { required: true, message: '请选择', trigger: 'change' },
        num: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_group: this.$userInfo.dict_group(),
      dict_isForbidden: this.$userInfo.isForbidden(),
      dict_allocation: this.$userInfo.dict_allocation(),
      product_disposable: this.$userInfo.product_disposable(),
      codeTypeList: [],
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {},
      dict_isUsed: this.$userInfo.dict_isUsed()
    }
  },
  created () {
    this.$userInfo.getDataList(this, 't_season').then(value => {
      this.dict_season = value
      this.getFlagList()
      this.getDataList()
    })
  },
  methods: {
    // 获取产品标识列表
    getFlagList () {
      getDetailFlagList({}).then((res) => {
        console.log('getFlagListData', res)
        this.codeTypeList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryConversionCodeList(this.queryForm).then((res) => {
        console.log('queryConversionCodeList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 选择产品回调
    codeTypeChange (e) {
      for (const key in this.codeTypeList) {
        if (Object.prototype.hasOwnProperty.call(this.codeTypeList, key)) {
          const element = this.codeTypeList[key]
          if (element.productDetailId === e) {
            console.log('element====', element.productDetailId)
            console.log('this.editInfoRules====', this.editInfoForm)
            this.editInfoForm.productFlag = element.flag
            this.editInfoForm.productId = element.productId
            this.editInfoForm.productDetailId = element.productDetailId
          }
        }
      }
    },
    codeTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.codeTypeList) {
        if (Object.hasOwnProperty.call(this.codeTypeList, key)) {
          const element = this.codeTypeList[key]
          if (element.productDetailId === data.productDetailId) {
            temp = element.productName
          }
        }
      }
      return temp
    },
    // 保存新增code点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        addConversionCode(this.editInfoForm).then((res) => {
          this.editInfoDialogVisible = false
          this.$message.success('新增成功！')
          this.$refs.editInfoFormRef.resetFields()
          this.reset()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    showChangePopViiew (rowData) {
      this.changeQueryForm.codeDescription = rowData.codeDescription
      this.changeQueryForm.id = rowData.id
      this.distributionStateDialogVisible = true
    },
    changeState () {
      updateAllocation(this.changeQueryForm).then((res) => {
        this.distributionStateDialogVisible = false
        this.$message.success('修改成功！')
        this.$refs.changeQueryFormRef.resetFields()
        this.reset()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    importCodeClicked () {
      // 导入线上成绩
      this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'code/importConversionCodeList'
      this.importCodeDialogVisible = true
    },
    // 弹窗关闭
    editInfoDialogClose () {
      this.showEditInfoForm = false
      this.editInfoForm.schoolArea = null
      this.$refs.editInfoFormRef.resetFields()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    downloadCodeData () {
      var downLoadUrl = this.$env.baseIP + 'code/exportConversionCodeList'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 批量上传成功
    scoreImportInfoSuccess (res) {
      console.log('res____________-', res)
      this.$curUtils.closeLoading(this)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + '<br/>' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('批量上传信息成功')
      this.reset()
      this.importCodeDialogVisible = false
    },
    scoreImportInfoError (error, file, fileList) {
      console.log('batchImportInfoError', error, file, fileList)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$curUtils.closeLoading()
      this.$message.error(error)
    },
    scoreImportInfoExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.scoreImportInfoRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    beginUpLoadData () {
      this.$refs.scoreImportInfoRef.submit()
    },
    // 上传成绩加载
    scoreImportInfoProgress () {
    },
    // 监听上传文件弹窗关闭事件
    importScoreDialogClose () {
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
    },
    // 选择文件修改状态
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
    text-align: right;
}
</style>
